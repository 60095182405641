import { AxiosResponse } from 'axios'
import { APIRequest } from '@/core/infrastructure/api/APIRequest'
import { HTTPMethod } from '@/core/infrastructure/api/APIClient'

export namespace APIBasePlugin {

  export class BasePluginQuery implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.GET;
    parse = (data: AxiosResponse) => data.data
    constructor (plugin: string, payload: object = {}) {
      let params = new URLSearchParams()
      for (let key in payload) {
        if (payload.hasOwnProperty(key)) {
          params.append(key, payload[key])
        }
      }
      this.path = `/registryservice/plugins/execute/${plugin}${params.toString().length ? '?' + params.toString() : ''}`
    }
  }

  export class BasePluginCommand implements APIRequest<string> {
    response: string;
    path: string;
    method = HTTPMethod.POST;
    parse = (data: AxiosResponse) => data.data
    constructor (plugin: string, public params: object = {}) {
      this.path = `/registryservice/plugins/execute/${plugin}`
    }
  }
}
